// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageHeader {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 20px;
  padding-bottom: 20px;
}
.PageHeaderTitle {
  font-weight: 600;
  font-size: 16px;
  grid-column-start: 1;
  flex-grow: 0.1;
  padding-top: 10px;
}
.PageHeaderCTA {
  grid-column-start: 4;
  grid-column-end: 6;
  align-content: center;
}
.PageHeaderDescription {
  grid-column-start: 1;
  grid-column-end: 5;
}

@media screen and (max-width: 1200px) {
  .PageHeader {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 20px;
    padding-bottom: 20px;
  }
  .PageHeaderCTA {
    grid-column-start: 1;
    align-content: center;
  }
  .PageHeaderDescription {
    grid-column-start: 1;
  }
}

@media screen and (min-width: 2000px) {
  .PageHeaderCTA {
    grid-column-start: 5;
    grid-column-end: 6;
    align-content: center;
  }
}
`, "",{"version":3,"sources":["webpack://./views/components/PageHeader/PageHeader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,gBAAgB;EAChB,oBAAoB;AACtB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,oBAAoB;EACpB,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,qBAAqB;AACvB;AACA;EACE,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE;IACE,aAAa;IACb,qCAAqC;IACrC,gBAAgB;IAChB,oBAAoB;EACtB;EACA;IACE,oBAAoB;IACpB,qBAAqB;EACvB;EACA;IACE,oBAAoB;EACtB;AACF;;AAEA;EACE;IACE,oBAAoB;IACpB,kBAAkB;IAClB,qBAAqB;EACvB;AACF","sourcesContent":[".PageHeader {\n  display: grid;\n  grid-template-columns: repeat(5, 1fr);\n  column-gap: 20px;\n  padding-bottom: 20px;\n}\n.PageHeaderTitle {\n  font-weight: 600;\n  font-size: 16px;\n  grid-column-start: 1;\n  flex-grow: 0.1;\n  padding-top: 10px;\n}\n.PageHeaderCTA {\n  grid-column-start: 4;\n  grid-column-end: 6;\n  align-content: center;\n}\n.PageHeaderDescription {\n  grid-column-start: 1;\n  grid-column-end: 5;\n}\n\n@media screen and (max-width: 1200px) {\n  .PageHeader {\n    display: grid;\n    grid-template-columns: repeat(1, 1fr);\n    column-gap: 20px;\n    padding-bottom: 20px;\n  }\n  .PageHeaderCTA {\n    grid-column-start: 1;\n    align-content: center;\n  }\n  .PageHeaderDescription {\n    grid-column-start: 1;\n  }\n}\n\n@media screen and (min-width: 2000px) {\n  .PageHeaderCTA {\n    grid-column-start: 5;\n    grid-column-end: 6;\n    align-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
