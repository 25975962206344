// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AccountPage {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 35px;
  margin: 0;
}
.AccountPageBanner {
  display: flex;
  justify-content: space-between;
}

.AccountLink {
  align-self: flex-start;
  color: var(--text-secondary);
}
@media screen and (min-width: 900px) {
  .AccountPage {
    padding: 70px 125px 70px 24px;
  }
}
`, "",{"version":3,"sources":["webpack://./views/pages/AccountPage/AccountPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,SAAS;AACX;AACA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;EACtB,4BAA4B;AAC9B;AACA;EACE;IACE,6BAA6B;EAC/B;AACF","sourcesContent":[".AccountPage {\n  display: flex;\n  flex-grow: 1;\n  flex-direction: column;\n  padding: 35px;\n  margin: 0;\n}\n.AccountPageBanner {\n  display: flex;\n  justify-content: space-between;\n}\n\n.AccountLink {\n  align-self: flex-start;\n  color: var(--text-secondary);\n}\n@media screen and (min-width: 900px) {\n  .AccountPage {\n    padding: 70px 125px 70px 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
