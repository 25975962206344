export const SITE_NAMES = {
  ME: 'Melbourne',
  AD: 'Adelaide',
  PE: 'Perth',
  JE: 'Jena',
  CP: 'Copenhagen',
  BM: 'Birmingham',
  AM: 'Amsterdam',
  LS: 'Lausanne',
  GW: 'Gwangju',
  SG: 'Singapore',
  HK: 'Hong Kong',
  CG: 'Cologne',
  ST: 'Santiago',
  LA: 'UCLA',
  OR: 'Oregon',
  BI: 'BIDMC',
  NL: 'Northwell',
  NC: 'UNC',
  SD: 'UCSD',
  CA: 'Calgary',
  YA: 'Yale',
  SF: 'UCSF',
  PA: 'Penn',
  SI: 'Mt. Sinai',
  PI: 'Pittsburgh',
  NN: 'Northwestern',
  IR: 'UC Irvine',
  TE: 'Temple',
  GA: 'Georgia',
  WU: 'WashU',
  HA: 'Hartford',
  MT: 'Montreal',
  KC: "King's College London",
  PV: 'Pavia',
  MA: 'Madrid',
  CM: 'Cambridge UK',
  MU: 'Munich',
  SH: 'Shanghai',
  SL: 'Seoul',
  Totals: 'Totals',
}
