// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeroCard_container {
  box-shadow: 0 0 0 0;
  width: 350px;
}
.HeroCard_button {
  text-transform: none;
  border-radius: 8px;
}
.HeroCard_image {
  height: 80px;
}

@media screen and (min-width: 900px) {
  .HeroCard_container {
    width: 565px;
    /* flex-grow: 1; */
  }
  .HeroCard_image {
    height: 106px;
    width: 476px;
  }
}
`, "",{"version":3,"sources":["webpack://./views/components/HeroCard/HeroCard.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,oBAAoB;EACpB,kBAAkB;AACpB;AACA;EACE,YAAY;AACd;;AAEA;EACE;IACE,YAAY;IACZ,kBAAkB;EACpB;EACA;IACE,aAAa;IACb,YAAY;EACd;AACF","sourcesContent":[".HeroCard_container {\n  box-shadow: 0 0 0 0;\n  width: 350px;\n}\n.HeroCard_button {\n  text-transform: none;\n  border-radius: 8px;\n}\n.HeroCard_image {\n  height: 80px;\n}\n\n@media screen and (min-width: 900px) {\n  .HeroCard_container {\n    width: 565px;\n    /* flex-grow: 1; */\n  }\n  .HeroCard_image {\n    height: 106px;\n    width: 476px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
