// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SidebarLogo_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 16px;
}
.SidebarLogo_image {
  height: 45px;
}
`, "",{"version":3,"sources":["webpack://./views/components/Sidebar/SidebarLogo.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,YAAY;AACd","sourcesContent":[".SidebarLogo_container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 32px 16px;\n}\n.SidebarLogo_image {\n  height: 45px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
