// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchInput {
  padding: 10px;
  position: sticky;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 100;
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./views/components/SearchSelect/SearchInput.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,SAAS;EACT,QAAQ;EACR,MAAM;EACN,YAAY;EACZ,uBAAuB;AACzB","sourcesContent":[".SearchInput {\n  padding: 10px;\n  position: sticky;\n  bottom: 0;\n  right: 0;\n  top: 0;\n  z-index: 100;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
