// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserProfileForm_profileImage {
  display: flex;
  align-items: center;
  padding: 24px 0px;
}
.UserProfileFormFileUploadInput {
  display: none;
}
.UserProfileFormAlert {
  max-width: 502px;
  padding-bottom: 24px;
}
.UserProfileFormFileInfo {
  padding-right: 14px;
}
`, "",{"version":3,"sources":["webpack://./views/forms/UserProfileForm/UserProfileImage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,oBAAoB;AACtB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".UserProfileForm_profileImage {\n  display: flex;\n  align-items: center;\n  padding: 24px 0px;\n}\n.UserProfileFormFileUploadInput {\n  display: none;\n}\n.UserProfileFormAlert {\n  max-width: 502px;\n  padding-bottom: 24px;\n}\n.UserProfileFormFileInfo {\n  padding-right: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
