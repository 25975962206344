// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeroFooter_container {
  bottom: 0;
  display: flex;
  gap: 60px;
  left: 0;
  padding: 24px 72px;
  right: 0;
}

.HeroFooter_linksContainer {
  display: flex;
  gap: 40px;
}

@media screen and (max-width: 500px) {
  .HeroFooter_container {
    flex-direction: column-reverse;
    gap: 20px;
    padding: 20px;
  }
  .HeroFooter_linksContainer {
    gap: 20px;
    flex-direction: column;
  }
}

@media screen and (min-width: 700px) {
  .HeroFooter_container {
    position: fixed;
  }
  .HeroFooter_linksContainer {
    gap: 24px;
  }
}

@media screen and (min-width: 1000px) {
  .HeroFooter_linksContainer {
    gap: 80px;
  }
}
`, "",{"version":3,"sources":["webpack://./views/components/HeroFooter/HeroFooter.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,aAAa;EACb,SAAS;EACT,OAAO;EACP,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE;IACE,8BAA8B;IAC9B,SAAS;IACT,aAAa;EACf;EACA;IACE,SAAS;IACT,sBAAsB;EACxB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,SAAS;EACX;AACF;;AAEA;EACE;IACE,SAAS;EACX;AACF","sourcesContent":[".HeroFooter_container {\n  bottom: 0;\n  display: flex;\n  gap: 60px;\n  left: 0;\n  padding: 24px 72px;\n  right: 0;\n}\n\n.HeroFooter_linksContainer {\n  display: flex;\n  gap: 40px;\n}\n\n@media screen and (max-width: 500px) {\n  .HeroFooter_container {\n    flex-direction: column-reverse;\n    gap: 20px;\n    padding: 20px;\n  }\n  .HeroFooter_linksContainer {\n    gap: 20px;\n    flex-direction: column;\n  }\n}\n\n@media screen and (min-width: 700px) {\n  .HeroFooter_container {\n    position: fixed;\n  }\n  .HeroFooter_linksContainer {\n    gap: 24px;\n  }\n}\n\n@media screen and (min-width: 1000px) {\n  .HeroFooter_linksContainer {\n    gap: 80px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
