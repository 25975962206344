// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ParticipantsSearchForm {
  display: flex;
  gap: 16px;
  align-items: center;
}

@media screen and (max-width: 1100px) {
  .ParticipantsSearchForm {
    flex-direction: column;
    align-items: normal;
  }
}
`, "",{"version":3,"sources":["webpack://./views/forms/ParticipantsSearchForm/ParticipantsSearchForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE;IACE,sBAAsB;IACtB,mBAAmB;EACrB;AACF","sourcesContent":[".ParticipantsSearchForm {\n  display: flex;\n  gap: 16px;\n  align-items: center;\n}\n\n@media screen and (max-width: 1100px) {\n  .ParticipantsSearchForm {\n    flex-direction: column;\n    align-items: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
