// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BarChartFieldsValueLabel {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-top: 20px;
}
.BarChartFieldsTargetValues {
  display: flex;
  align-items: center;
}
.BarChartFieldsAddTargets {
  padding-top: 20px;
}
@media screen and (max-width: 800px) {
  .BarChartFieldsValueLabel {
    width: auto;
  }
}

@media screen and (min-width: 900px) {
  .BarChartFieldsValueLabel {
    width: 50%;
  }
}
`, "",{"version":3,"sources":["webpack://./views/forms/BarChartFields/BarChartFields.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB;AACA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".BarChartFieldsValueLabel {\n  display: flex;\n  flex-direction: column;\n  padding-bottom: 20px;\n  padding-top: 20px;\n}\n.BarChartFieldsTargetValues {\n  display: flex;\n  align-items: center;\n}\n.BarChartFieldsAddTargets {\n  padding-top: 20px;\n}\n@media screen and (max-width: 800px) {\n  .BarChartFieldsValueLabel {\n    width: auto;\n  }\n}\n\n@media screen and (min-width: 900px) {\n  .BarChartFieldsValueLabel {\n    width: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
