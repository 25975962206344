// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-light: #addbff;
  --primary-main: #2196f3;
  --text-secondary: #054da7;
  --dark-transparent-grey: #616161e6;
  --underline-grey: #0000001f;
}

.Link--unstyled {
  text-decoration: none;
}

.Link--unstyled {
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./views/App.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,uBAAuB;EACvB,yBAAyB;EACzB,kCAAkC;EAClC,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[":root {\n  --primary-light: #addbff;\n  --primary-main: #2196f3;\n  --text-secondary: #054da7;\n  --dark-transparent-grey: #616161e6;\n  --underline-grey: #0000001f;\n}\n\n.Link--unstyled {\n  text-decoration: none;\n}\n\n.Link--unstyled {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
