// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainLayout_container {
  display: flex;
}

.MainLayout_main {
  flex-shrink: 0;
  flex: 1;
}
@media screen and (max-width: 900px) {
  .MainLayout_main {
    margin-top: 50px;
  }
}
@media screen and (min-width: 900px) {
  .MainLayout_main {
    margin-left: 253px;
  }
}
`, "",{"version":3,"sources":["webpack://./views/layouts/MainLayout/MainLayout.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,OAAO;AACT;AACA;EACE;IACE,gBAAgB;EAClB;AACF;AACA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".MainLayout_container {\n  display: flex;\n}\n\n.MainLayout_main {\n  flex-shrink: 0;\n  flex: 1;\n}\n@media screen and (max-width: 900px) {\n  .MainLayout_main {\n    margin-top: 50px;\n  }\n}\n@media screen and (min-width: 900px) {\n  .MainLayout_main {\n    margin-left: 253px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
