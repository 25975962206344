// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignInPageRegister_link {
  text-decoration: none;
  padding-left: 6px;
  color: var(--primary-main);
}

.SignInPageResetPW_link {
  padding-top: 16px;
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./views/pages/SignInPage/SignInPage.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,0BAA0B;AAC5B;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;AACvB","sourcesContent":[".SignInPageRegister_link {\n  text-decoration: none;\n  padding-left: 6px;\n  color: var(--primary-main);\n}\n\n.SignInPageResetPW_link {\n  padding-top: 16px;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
