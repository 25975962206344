// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BarGraphTooltip {
  display: flex;
}
.BarGraphTooltip_data {
  display: grid;
  max-width: 225px;
  background: var(--dark-transparent-grey);
  color: white;
  padding: 10px;
  border-radius: 10px;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 20px;
  font-size: 14px;
}
.arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--dark-transparent-grey);
  align-self: center;
}
.arrow-right {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid var(--dark-transparent-grey);
  align-self: center;
}
.BarGraphTooltip_label {
  grid-column-start: 1;
  grid-column-end: 5;
}
.BarGraphTooltip_values {
  grid-column-start: 5;
  grid-column-end: 6;
  text-align: right;
}
.BarGraphTooltip_divider {
  grid-column-start: 1;
  grid-column-end: 6;
  height: 3px;
  background-color: var(--underline-grey);
}
`, "",{"version":3,"sources":["webpack://./views/components/BarGraphTooltip/BarGraphTooltip.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,wCAAwC;EACxC,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,qCAAqC;EACrC,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,QAAQ;EACR,SAAS;EACT,kCAAkC;EAClC,qCAAqC;EACrC,qDAAqD;EACrD,kBAAkB;AACpB;AACA;EACE,QAAQ;EACR,SAAS;EACT,kCAAkC;EAClC,qCAAqC;EACrC,oDAAoD;EACpD,kBAAkB;AACpB;AACA;EACE,oBAAoB;EACpB,kBAAkB;AACpB;AACA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,WAAW;EACX,uCAAuC;AACzC","sourcesContent":[".BarGraphTooltip {\n  display: flex;\n}\n.BarGraphTooltip_data {\n  display: grid;\n  max-width: 225px;\n  background: var(--dark-transparent-grey);\n  color: white;\n  padding: 10px;\n  border-radius: 10px;\n  grid-template-columns: repeat(5, 1fr);\n  column-gap: 20px;\n  font-size: 14px;\n}\n.arrow-left {\n  width: 0;\n  height: 0;\n  border-top: 10px solid transparent;\n  border-bottom: 10px solid transparent;\n  border-right: 10px solid var(--dark-transparent-grey);\n  align-self: center;\n}\n.arrow-right {\n  width: 0;\n  height: 0;\n  border-top: 10px solid transparent;\n  border-bottom: 10px solid transparent;\n  border-left: 10px solid var(--dark-transparent-grey);\n  align-self: center;\n}\n.BarGraphTooltip_label {\n  grid-column-start: 1;\n  grid-column-end: 5;\n}\n.BarGraphTooltip_values {\n  grid-column-start: 5;\n  grid-column-end: 6;\n  text-align: right;\n}\n.BarGraphTooltip_divider {\n  grid-column-start: 1;\n  grid-column-end: 6;\n  height: 3px;\n  background-color: var(--underline-grey);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
