// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GraphTable_Top {
  padding: 25px;
  display: flex;
  justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./views/components/GraphTable/GraphTable.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,yBAAyB;AAC3B","sourcesContent":[".GraphTable_Top {\n  padding: 25px;\n  display: flex;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
