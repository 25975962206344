// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ColorPaletteDropdown {
  display: table;
  width: 100%;
}
.ColorPaletteBlock {
  width: 20px;
  height: 20px;
  display: table-cell;
}
.RangeFields {
  display: 'flex';
}
`, "",{"version":3,"sources":["webpack://./views/forms/ConfigAssessmentFormFields/ConfigAssessmentFormFields.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;AACb;AACA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB","sourcesContent":[".ColorPaletteDropdown {\n  display: table;\n  width: 100%;\n}\n.ColorPaletteBlock {\n  width: 20px;\n  height: 20px;\n  display: table-cell;\n}\n.RangeFields {\n  display: 'flex';\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
