// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfigFormActions {
  position: fixed;
  bottom: 0;
  right: 0;
  border: 0;
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(1fr, 3);
  gap: 10px;
  font-size: 12px;
}

@media screen and (min-width: 1000px) {
  .ConfigForm {
    padding-top: 40px;
  }
}
`, "",{"version":3,"sources":["webpack://./views/forms/ConfigForm/ConfigForm.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,QAAQ;EACR,SAAS;EACT,aAAa;EACb,aAAa;EACb,qCAAqC;EACrC,SAAS;EACT,eAAe;AACjB;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".ConfigFormActions {\n  position: fixed;\n  bottom: 0;\n  right: 0;\n  border: 0;\n  padding: 24px;\n  display: grid;\n  grid-template-columns: repeat(1fr, 3);\n  gap: 10px;\n  font-size: 12px;\n}\n\n@media screen and (min-width: 1000px) {\n  .ConfigForm {\n    padding-top: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
