// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfigTypeFormFields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'name type'
    'description share';
  grid-gap: 10%;
}
.ConfigTypeShareContainer {
  display: flex;
  grid-area: share;
  flex-direction: column;
}
.ConfigTypeShareHeader {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'share_label make_public';
}

@media screen and (max-width: 1100px) {
  .ConfigTypeFormFields {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'name'
      'type'
      'description'
      'share';
    grid-gap: 15px;
  }
}

@media screen and (max-width: 600px) {
  .ConfigTypeShareHeader {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'make_public'
      'share_label';
  }
}
`, "",{"version":3,"sources":["webpack://./views/forms/ConfigTypeFormFields/ConfigTypeFormFields.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B;;uBAEqB;EACrB,aAAa;AACf;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,8CAA8C;AAChD;;AAEA;EACE;IACE,aAAa;IACb,0BAA0B;IAC1B;;;;aAIS;IACT,cAAc;EAChB;AACF;;AAEA;EACE;IACE,aAAa;IACb,0BAA0B;IAC1B;;mBAEe;EACjB;AACF","sourcesContent":[".ConfigTypeFormFields {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-template-areas:\n    'name type'\n    'description share';\n  grid-gap: 10%;\n}\n.ConfigTypeShareContainer {\n  display: flex;\n  grid-area: share;\n  flex-direction: column;\n}\n.ConfigTypeShareHeader {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-template-areas: 'share_label make_public';\n}\n\n@media screen and (max-width: 1100px) {\n  .ConfigTypeFormFields {\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-template-areas:\n      'name'\n      'type'\n      'description'\n      'share';\n    grid-gap: 15px;\n  }\n}\n\n@media screen and (max-width: 600px) {\n  .ConfigTypeShareHeader {\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-template-areas:\n      'make_public'\n      'share_label';\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
