// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChartPageDetails {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding-bottom: 20px;
}
.ChartPageOwner {
  display: flex;
  grid-column-start: 4;
  grid-column-end: 12;
}
`, "",{"version":3,"sources":["webpack://./views/pages/ViewChartPage/ViewChartPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sCAAsC;EACtC,oBAAoB;AACtB;AACA;EACE,aAAa;EACb,oBAAoB;EACpB,mBAAmB;AACrB","sourcesContent":[".ChartPageDetails {\n  display: grid;\n  grid-template-columns: repeat(12, 1fr);\n  padding-bottom: 20px;\n}\n.ChartPageOwner {\n  display: flex;\n  grid-column-start: 4;\n  grid-column-end: 12;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
