// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ColorPicker {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
}
.ColorPickerPalette {
  max-width: 200px;
  display: flex;
  padding: 6px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.ColorPickerSwatch {
  width: 24px;
  height: 24px;
  margin: 4px;
  border: none;
  padding: 0;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  color: transparent;
}
`, "",{"version":3,"sources":["webpack://./views/components/ColorPicker/ColorPicker.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,YAAY;EACZ,eAAe;EACf,8BAA8B;AAChC;AACA;EACE,WAAW;EACX,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,UAAU;EACV,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,kBAAkB;AACpB","sourcesContent":[".ColorPicker {\n  display: flex;\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n.ColorPickerPalette {\n  max-width: 200px;\n  display: flex;\n  padding: 6px;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n.ColorPickerSwatch {\n  width: 24px;\n  height: 24px;\n  margin: 4px;\n  border: none;\n  padding: 0;\n  border-radius: 4px;\n  cursor: pointer;\n  outline: none;\n  color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
