// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SidebarFooter_container {
  margin-top: auto;
  padding-left: 24px;
}

.SidebarFooter_content {
  padding: 8px 0;
}

.SidebarFooter_actions {
  display: flex;
  gap: 16px;
}

.SidebarFooter_version {
  padding: 16px;
}
`, "",{"version":3,"sources":["webpack://./views/components/Sidebar/SidebarFooter.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;AACf","sourcesContent":[".SidebarFooter_container {\n  margin-top: auto;\n  padding-left: 24px;\n}\n\n.SidebarFooter_content {\n  padding: 8px 0;\n}\n\n.SidebarFooter_actions {\n  display: flex;\n  gap: 16px;\n}\n\n.SidebarFooter_version {\n  padding: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
