// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfigurationsPageActions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
`, "",{"version":3,"sources":["webpack://./views/pages/ConfigurationsPage/ConfigurationsPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAS;AACX","sourcesContent":[".ConfigurationsPageActions {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
