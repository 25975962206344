// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChartFilterForm {
  display: grid;
  width: 100%;
  gap: 10px;
  padding-bottom: 20px;
  grid-template-columns: repeat(1fr, 4);
  grid-template-rows: auto;
}
.ChartFilterForm_container {
  display: flex;
  padding-left: 15px;
}
.ChartFilterForm_text {
  margin-top: 0;
  margin-bottom: 0;
  text-indent: 15px;
}
.ChartFilterForm_nested {
  padding: 0;
}
.ChartFilterForm_submit {
  padding-top: 20px;
  width: 158px;
  padding-left: 15px;
}

@media screen and (max-width: 1040px) {
  .ChartFilterForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    padding-bottom: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./views/forms/ChartFilterForm/ChartFilterForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,SAAS;EACT,oBAAoB;EACpB,qCAAqC;EACrC,wBAAwB;AAC1B;AACA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,UAAU;AACZ;AACA;EACE,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,SAAS;IACT,oBAAoB;EACtB;AACF","sourcesContent":[".ChartFilterForm {\n  display: grid;\n  width: 100%;\n  gap: 10px;\n  padding-bottom: 20px;\n  grid-template-columns: repeat(1fr, 4);\n  grid-template-rows: auto;\n}\n.ChartFilterForm_container {\n  display: flex;\n  padding-left: 15px;\n}\n.ChartFilterForm_text {\n  margin-top: 0;\n  margin-bottom: 0;\n  text-indent: 15px;\n}\n.ChartFilterForm_nested {\n  padding: 0;\n}\n.ChartFilterForm_submit {\n  padding-top: 20px;\n  width: 158px;\n  padding-left: 15px;\n}\n\n@media screen and (max-width: 1040px) {\n  .ChartFilterForm {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    gap: 20px;\n    padding-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
