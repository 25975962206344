// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfigFields {
  display: flex;
  gap: 10px;
  flex-flow: wrap;
  padding-top: 40px;
}
`, "",{"version":3,"sources":["webpack://./views/forms/ConfigFields/ConfigFields.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".ConfigFields {\n  display: flex;\n  gap: 10px;\n  flex-flow: wrap;\n  padding-top: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
