// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SidebarLink_link {
  border-radius: 8px;
  display: flex;
  gap: 20px;
  text-decoration: none;
  padding: 6px 12px;

  &:visited&:focus {
    text-decoration: none;
  }
}

.SidebarLink_link-active {
  display: flex;
  text-decoration: none;
  &:visited&:focus {
    text-decoration: none;
  }
  background-color: var(--primary-light);
  border-radius: 8px;
}

.SidebarLink_link-active .MuiListItemIcon-root {
  color: var(--text-secondary);
}

.SidebarLink_link-active .MuiListItemText-root {
  color: var(--text-secondary);
}
`, "",{"version":3,"sources":["webpack://./views/components/Sidebar/SidebarLink.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,SAAS;EACT,qBAAqB;EACrB,iBAAiB;;EAEjB;IACE,qBAAqB;EACvB;AACF;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB;IACE,qBAAqB;EACvB;EACA,sCAAsC;EACtC,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".SidebarLink_link {\n  border-radius: 8px;\n  display: flex;\n  gap: 20px;\n  text-decoration: none;\n  padding: 6px 12px;\n\n  &:visited&:focus {\n    text-decoration: none;\n  }\n}\n\n.SidebarLink_link-active {\n  display: flex;\n  text-decoration: none;\n  &:visited&:focus {\n    text-decoration: none;\n  }\n  background-color: var(--primary-light);\n  border-radius: 8px;\n}\n\n.SidebarLink_link-active .MuiListItemIcon-root {\n  color: var(--text-secondary);\n}\n\n.SidebarLink_link-active .MuiListItemText-root {\n  color: var(--text-secondary);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
